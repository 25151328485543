import React from 'react'
import { Container } from 'react-bootstrap'
import styles from '../../styles/Home.module.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { clientslist1, clientslist2 } from '../../data/clients.js'

const Clients = () => {
  const randomNumber = Math.random() > 0.2 ? clientslist1 : clientslist2
  let list = randomNumber?.map((res, i) => {
    return <LazyLoadImage key={i} src={res.logo} className={styles.clientsNewSpacing} width={'100%'} alt="Natural" />
  })

  return (
    <Container>
      <div className={styles.marquee} style={{ marginTop: 40 }}>
        <div className={styles.track}>
          <div className={styles.content}>{list}</div>
        </div>
      </div>
      {/* <div className={styles.clientsNew}>
        <LazyLoadImage src={'https://cdn.webhr.co/images/Web/ClientLogos/Set1/Logo9.gif'} className={styles.clientsNewSpacing} alt="Natural" />
        <LazyLoadImage src={'https://cdn.webhr.co/images/Web/ClientLogos/Set1/Logo2.gif'} className={styles.clientsNewSpacing} alt="embassyOfCanada" />
        <LazyLoadImage src={'https://cdn.webhr.co/images/Web/ClientLogos/Set2/Logo10.gif'} className={styles.clientsNewSpacing} alt="RCG" />
        <LazyLoadImage src={'https://cdn.webhr.co/images/Web/ClientLogos/Set2/Logo11.gif'} className={styles.clientsNewSpacing} alt="Cravia" />
        <LazyLoadImage src={'https://cdn.webhr.co/images/Web/ClientLogos/Set1/Logo5.gif'} className={styles.clientsNewSpacing} alt="payactive" />
        <LazyLoadImage src={'https://cdn.webhr.co/images/Web/ClientLogos/Set1/Logo11.gif'} className={styles.clientsNewSpacing} alt="Cravia" />
      </div> */}
    </Container>
  )
}

export default React.memo(Clients)
